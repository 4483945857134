const Tailwind = () => {
  return (
    <div>
      <div className=" h-max-screen w-full md:h-96 px-4 py-12  h-1/2 md:px-[18%] text-center  text-blue-200 bg-slate-900">
        <p className="lg:text-3xl font-light  mt-8 mr-3 ">
          <q>
            The key to making great investments is to assume that the past is
            wrong, and to do something that&#39;s not part of the past, to do
            something entirely differently. I asked what was the most outrageous
            thing you&#39;ve ever done, knowing in my heart of hearts that
            I&#39;d pick the one who&#39;d done something most outrageous.
          </q>
        </p>
        <p className=" p-6 md:text-2xl text-gray-400 font-light">
          - Don Valentine{" "}
        </p>
      </div>
    </div>
  );
};

export default Tailwind;
