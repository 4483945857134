import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="m:h-96  m:p-20 m:w-3/4  mx-auto pt-12 pb-12 bg-slate-100"
    >
      <div className="text-center md:p-32 ">
        <p className=" font-public text-sky-900 m:p-36 text-xl md:text-2xl p-12">
          Rinconada Capital Club is focused on investing in early stage deep
          tech companies that are founded in Ireland and Europe. Typical deals
          are in the $1M-$5M range.
        </p>
      </div>
    </div>
  );
};

export default About;
